<template>
    <div>
        <div class="full-width category-container">
            <div class="container">

                <div class="col-12 category-content">
                    
                    <div>
                        <Breadcrumbs :path="path" />
                    </div>

                    <h3 style="font-size:30px;fot-weight:bolder;margin-bottom:1rem;">Fair Tips, il blog di Altromercato</h3>
                    <p class="cat-descr">Siamo convinti che le scelte quotidiane di ognuno di noi possano cambiare verso al mondo. Qui parleremo di scelte di vita sostenibili, di ricette consapevoli, dei prodotti delle nostre filiere etiche e delle novità dal Commercio Equo e Solidale. Ti va di saperne di più?</p>

                    <div class="container big-banner-caffe big-banner promonews" :style="'color: #fff; background-image: url(' + featured._embedded['wp:featuredmedia']['0'].source_url + '); margin-bottom: 30px; width: 100%; position: relative;background-size: cover;'" v-if="featured">
                      <div class="col-12 center-content" style="position: relative; z-index: 1">
                        <p class="big-p neue" style="margin-bottom: 25px; width: 500px; max-width: 95%; letter-spacing: 1px;"><b v-html="featured.title.rendered"></b></p>
                        <router-link router-link :to="`/post/${featured.id}/${featured.slug}`" class="button hover-nero">Scopri</router-link>
                      </div>
                    </div>

                    <BlogCategory :category="this.category" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import BlogCategory from '@/components/blogCategory/View'
    import utils from '@/utils.js'
    import axios from 'axios'

    export default {
    name: 'Blog',
    metaInfo: {
      title: 'Blog'
    },
    data: function () {
        return {
        path: [
            { label: 'Home', path: '/' },
            { label: 'Blog', path: '/blog' },
        ],
        category: 0,
        cat: {},
        parentCat: {},
        secondLevel: [],
        thirdLevel: {},
        childKey: 0,
        catPomodoro: ['EC81402'],
        catZucchero: ['EC71100','EC81101','EC81100'],
        catTessile: ['EC65000','EC75100', 'EC75000', 'EC76200'],
        catCaffe: ['EC71000','EC81000'],
        catCacao: ['EC71200', 'EC81200', 'EC81201', 'EC81202', 'EC81203', 'EC81204'],
        catPromoFlash: ['EF00004'],
        featured: false
        }
    },
    watch: {
        $route() {
            this.initToParam()
            this.childKey++
        }
    },

    computed: {
        catDescription () {
            let cat = ''

            this.$cats.forEach((c) => {
                if (c.code == this.$route.params.code) {
                cat = c.descr
                }
            })

            return cat
        }
    },

    async mounted () {
      try {
        let resp = await axios.get('https://altromercato.it/wp-json/wp/v2/posts?_embed&per_page=1&categories=45')

        if (resp.data.length) {
          this.featured = resp.data[0]
          console.log(this.featured)
        }
      } catch (e) {
        console.log(e)
      }

      //this.initToParam()
    },

    components: {
        Breadcrumbs,
        BlogCategory
    },

    methods: {
        changeCat(id){
            this.category = id
        },
        initToParam () {
            this.cat = utils.getCategory(this.$route.params.code)
            this.parentCat = utils.getSecondCategory(this.$route.params.code)
            this.secondLevel = utils.getSecondLevel(this.parentCat.categoryCode)
            this.path = [{
                label: 'Home',
                path: '/'
            }].concat(utils.getCategoryPath(this.$route.params.code).reverse())

            this.secondLevel.forEach((item) => {
                this.thirdLevel[item.categoryCode] = utils.getSecondLevel(item.categoryCode)
            })
        }
    }
    }
</script>

<style scoped>
    .category-container {
        margin-top: 38px;
        padding-bottom: 60px;
    }

    .category-content h2 {
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0 0 20px 0;
    }

    .category-content .cat-descr {
        margin: 0 0 25px 0;
        max-width: 66.66666%;
        line-height: 140%;
        font-size: 14px;
        font-weight: 500;
    }

    .single-filter {
        width: 100%;
        padding-right: 50px;
    }

    .single-filter .filter-name {
        display: block;
        font-weight: 800;
        font-size: 15px;
        margin-bottom: 20px;
        text-transform: uppercase;
        position: relative;
        height: 25px;
        line-height: 25px;
        margin-top: 0;
        cursor: pointer;
        letter-spacing: 0.5px;
    }

    .single-filter .filter-name:after {
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
    }

    .single-filter .filter-content {
        padding-left: 40px;
        margin-bottom: 40px;
    }

    .single-filter .filter-content a {
        display: block;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 12px;
    }

    .big-banner {
        height: 335px;
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
    }

    body .big-p {
        font-size: 30px;
    }

    .promonews:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /*background-color: rgba(255,255,255,0.45);*/
      background-color: rgba(0,0,0,0.45);
      content: '';
      display: block;
      z-index: 0;
    }

    @media (max-width: 680px) {
        .big-banner {
            background-size: cover;
            padding-top: 70px;
        }

        .category-content h3 {
            text-align: center;
        }

        .single-filter {
            margin: 0 5px 0 0;
        }

        .category-content h2 {
            text-align: center;
            font-size: 28px;
        }

        .category-content .cat-descr {
            margin: 0 10px;
            max-width: none;
            margin-bottom: 30px;
        }
    }

    .little-banner img {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }


    .promonews .button {
      background-color: #fff;
      color: #000;
    }

    .hover-nero:hover {
      background-color: #000;
    }
</style>
